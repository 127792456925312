<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-secondary border-0 mb-0">
                        <div class="row justify-content-center">
                            <div class="col-6 m-3">
                                <img
                                    src="img/brand/logos/otinga.svg"
                                    class="img-fluid"
                                />
                            </div>
                        </div>
                        <b-row class="justify-content-center">
                            <b-col class="px-5 text-center">
                                <h1 class="text-primary">Welcome!</h1>
                                <p class="text-lead text-primary">
                                    Great news, <br />you have an invite wating
                                    for you.
                                </p>
                            </b-col>
                        </b-row>

                        <b-card-header
                            class="bg-transparent pb-5"
                            v-if="!logged_in"
                        >
                            <div class="text-muted text-center mt-2 mb-3">
                                <small
                                    >Please log in or register to
                                    continue.</small
                                >
                            </div>

                            <div class="btn-wrapper text-center">
                                <base-button
                                    type="primary"
                                    :pill="true"
                                    native-type="submit"
                                    class=""
                                    @click="route_to('Login')"
                                    >Log in
                                </base-button>
                                <small class="mr-2">or</small>
                                <base-button
                                    type="info"
                                    :pill="true"
                                    native-type="submit"
                                    class=""
                                    @click="route_to('Register')"
                                    >Register
                                </base-button>
                            </div>
                        </b-card-header>
                        <b-card-header class="bg-transparent pb-5" v-else>
                            <div class="btn-wrapper text-center">
                                <base-button
                                    type="primary"
                                    :pill="true"
                                    native-type="submit"
                                    class=""
                                    @click="reroute_in_case_logged_in()"
                                    >Continue
                                </base-button>
                            </div>
                        </b-card-header>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import store from "@/store";
export default {
    name: "HandleInvite",
    data() {
        return {
            invite_uid: null,
            logged_in: store.store.getters.isLogin,
        };
    },
    methods: {
        route_to(pathName) {
            this.$router.push({
                name: pathName,
                query: {
                    next: `${this.get_invite_base_path()}?uid=${
                        this.invite_uid
                    }`,
                },
            });
        },
        reroute_in_case_logged_in() {
            if (store.store.getters.isLogin) {
                this.$router.push({
                    path: `${this.get_invite_base_path()}?uid=${
                        this.invite_uid
                    }`,
                });
            }
        },
        get_invite_base_path() {
            if (this.$route.path.includes("/hackathon/judging/invite")) {
                return "/hackathon/judging/invite/view";
            } else if (this.$route.path.includes("/hackathon/mentors/invite")) {
                return "/hackathon/mentors/invite/view";
            } else if (this.$route.path.includes("/hackathon/heroes/invite")) {
                return "/hackathon/heroes/invite/view";
            } else if (
                this.$route.path.includes("/hackathon/organizing-team/invite")
            ) {
                return "/hackathon/judging/invite/view";
            } else if (
                this.$route.path.includes("/organization/members/invite")
            ) {
                return "/organization/members/invite/view";
            }
        },
        get_uid() {
            let invite_uid = this.$route.query.uid;

            if (invite_uid) {
                this.invite_uid = invite_uid;
            } else {
                this.$router.push({
                    path: "/login",
                });
            }
        },
    },
    beforeMount() {
        this.get_uid();
        this.logged_in = store.store.getters.isLogin;
    },
};
</script>
